/* Merged Dashboard styles.css */
@import url('https://fonts.googleapis.com/css2?family=Rye&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap');


:root {
  --poker-felt-green: #35654d;
  --poker-dark-edge: #0b2e1d;
  --shadow-light: rgba(0, 0, 0, 0.1);
  --shadow-heavy: rgba(0, 0, 0, 0.5);
  --highlight-gold: #FFD700;
  --highlight-gold-transparent: rgba(255, 215, 0, 0.3);
}
  


/* Base Styles */
body {
  margin: 0;
  font-family: 'Rye', cursive;
  color: white;
  min-height: 100vh;
  background-color: var(--poker-felt-green);
  background-image: 
    radial-gradient(circle at 50% 50%, var(--poker-felt-green) 80%, var(--poker-dark-edge) 100%),
    repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.15) 0,
      rgba(0, 0, 0, 0.15) 10px,
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0) 20px
    ),
    radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.05), transparent 60%);
  background-blend-mode: overlay;
  box-shadow: inset 0 0 100px var(--shadow-heavy);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  width: 90%;
  padding: 2rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  border: 3px solid var(--highlight-gold);
  box-shadow: 0 6px 30px var(--shadow-heavy), inset 0 0 80px var(--shadow-light);
  position: relative;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent 70%);
  opacity: 0.2;
  transform: rotate(30deg);
  z-index: 0;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: var(--highlight-gold);
  text-shadow: 3px 3px 10px var(--shadow-heavy), 0 0 15px var(--highlight-gold-transparent);
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

/* Dashboard Layout */
.dashboard-wrapper {
  min-height: calc(100vh - 64px); /* Subtract navbar height */
  width: 100%;
  padding: 1rem; /* Reduced from 2rem */
  background-color: var(--poker-green-light);
  background-image: 
    repeating-linear-gradient(45deg,
      var(--poker-green-light) 0, var(--poker-green-light) 10px,
      var(--poker-green-lighter) 10px, var(--poker-green-lighter) 20px
    );
}


.dashboard-container {
  max-width: 1280px;
  margin: 0 auto;
  background: var(--poker-dark);
  border: 2px solid var(--poker-gold);
  border-radius: 0.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5),
              inset 0 0 40px rgba(0, 0, 0, 0.3);
  padding: 1.5rem; /* Reduced from 2rem */
}

.dashboard-grid {
  display: grid;
  gap: 1rem;
  padding: 0.75rem; /* Reduced from 1rem */
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .dashboard-grid {
    grid-template-columns: minmax(250px, 1fr) 2fr; /* Adjusted ratio */
  }
}

/* Card Base Styles */
.card, .dashboard-card {
  background: var(--card-bg) !important;
  border: 1px solid var(--poker-gold) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5),
              inset 0 0 20px rgba(0, 0, 0, 0.2) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.card:hover, .dashboard-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2),
              inset 0 0 20px rgba(0, 0, 0, 0.2) !important;
}

/* Profile Section */
.profile-section {
  height: 100%;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%; /* Make sure it fills the card */
}

.button {
  font-family: 'Rye', cursive;
  font-size: 1.5rem;
  padding: 0.75rem 1.5rem;
  color: var(--poker-dark-edge);
  background: var(--highlight-gold);
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4), inset 0 0 8px rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  z-index: 1;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5), inset 0 0 10px rgba(255, 255, 255, 0.4);
}

.button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 0 5px rgba(255, 255, 255, 0.2);
}

.button-enhanced.w-full {
  margin-top: 1rem; /* Add space above the button */
  justify-content: center;
}
.stats-section {
  margin-bottom: auto; /* Push the button to the bottom */
}
.avatar-container {
  width: 4rem; /* Reduced from 6rem */
  height: 4rem; /* Reduced from 6rem */
  background: var(--poker-black-transparent);
  border: 2px solid var(--poker-gold);
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
}

.avatar-container:hover {
  transform: scale(1.05);
  border-color: var(--gold-light);
}

.avatar-icon {
  width: 2rem; /* Reduced from 3rem */
  height: 2rem; /* Reduced from 3rem */
  color: var(--poker-gold);
}

/* Stats Section */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem; /* Reduced from 1rem */
  width: 100%;
}

.stat-item {
  background: var(--poker-black-transparent);
  padding: 0.75rem; /* Reduced from 1rem */
  border-radius: 0.5rem;
  border: 1px solid var(--poker-gold-transparent);
  text-align: center;
  transition: transform 0.2s ease;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.stat-item:hover {
  transform: translateY(-2px);
  border-color: var(--poker-gold);
}

.stat-value {
  font-size: 1.25rem; /* Reduced from 1.5rem */
  font-weight: bold;
  color: var(--poker-gold);
  margin-bottom: 0.25rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.stat-label {
  font-size: 0.75rem; /* Reduced from 0.875rem */
  color: rgba(255, 215, 0, 0.6);
}

/* Card Headers and Content */
.card-title {
  font-size: 1rem !important; /* Reduced size */
  padding: 0.5rem 0 !important;
}

.card-content {
  padding: 0.75rem !important;
}

/* Input and Button Styles */
.input-enhanced {
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem;
}

.button-enhanced {
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem;
}

/* Section Headers */
.section-header {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

/* Profile Text */
.profile-name {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.profile-email {
  font-size: 0.75rem;
}

.venmo-handle {
  font-size: 0.75rem;
}

/* Spacing Utils */
.mb-4 {
  margin-bottom: 0.75rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

/* Input Fields */
.input-enhanced, .input-field {
  width: 100%;
  padding: 0.75rem 1rem;
  background: var(--poker-black-transparent) !important;
  border: 1px solid var(--poker-gold) !important;
  border-radius: 0.25rem !important;
  color: var(--poker-gold) !important;
  font-family: 'Rye', cursive;
  transition: all 0.2s ease;
}

.input-enhanced:focus, .input-field:focus {
  outline: none;
  border-color: var(--gold-light) !important;
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.3) !important;
}

.input-enhanced::placeholder, .input-field::placeholder {
  color: rgba(255, 215, 0, 0.3) !important;
}

/* Buttons */
.button-enhanced, .button-primary {
  width: 100%;
  padding: 0.75rem 1.5rem;
  background: var(--poker-gold) !important;
  color: var(--poker-dark) !important;
  border: none !important;
  border-radius: 0.25rem !important;
  font-family: 'Rye', cursive !important;
  font-weight: 600 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-enhanced:hover:not(:disabled), .button-primary:hover:not(:disabled) {
  background: var(--gold-light) !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
}

.button-enhanced:disabled, .button-primary:disabled {
  background: rgba(255, 215, 0, 0.5) !important;
  cursor: not-allowed;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0;
  font-family: 'Rye', cursive;
  font-weight: 600;
  color: var(--poker-gold) !important;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-link:hover {
  color: var(--gold-light) !important;
  text-decoration: underline;
}

/* Card Title and Headers */
.card-title {
  color: var(--poker-gold) !important;
  font-family: 'Rye', cursive !important;
  font-size: 1.5rem !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--poker-gold);
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--poker-gold-transparent);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Icons */
.card-icon, .section-icon {
  color: var(--poker-gold);
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

/* Profile Text */
.profile-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--poker-gold);
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.profile-email {
  color: rgba(255, 215, 0, 0.6);
  text-align: center;
}

/* Venmo Section */
.venmo-section {
  width: 100%;
  padding-top: 1rem;
  border-top: 1px solid var(--poker-gold-transparent);
}

.venmo-handle {
  color: rgba(255, 215, 0, 0.6);
  text-align: center;
}

/* Loading Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid var(--poker-gold-transparent);
  border-top-color: var(--poker-gold);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Error Alert */
.error-alert {
  margin-top: 1rem;
  background: rgba(139, 0, 0, 0.2) !important;
  border-color: #FF0000 !important;
  color: #FF4444 !important;
}
/* App Layout Styles */
.min-h-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--poker-green-light);
  background-image: 
    repeating-linear-gradient(45deg,
      var(--poker-green-light) 0, var(--poker-green-light) 10px,
      var(--poker-green-lighter) 10px, var(--poker-green-lighter) 20px
    );
}

.dashboard-wrapper {
  flex: 1;
  padding: 2rem;
  margin-top: 64px; /* Height of navbar */
}

/* Add this if you're using the Toaster component */
.toaster-wrapper {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
}
/* Loading animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Enhancing the existing loading spinner */
.loading-spinner {
  width: 3rem;
  height: 3rem;
  border: 3px solid var(--poker-gold-transparent);
  border-top-color: var(--poker-gold);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Global scrollbar for app */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--poker-dark);
}

::-webkit-scrollbar-thumb {
  background: var(--poker-gold);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gold-light);
}
/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-wrapper {
    padding: 1rem;
  }
  
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .avatar-container {
    width: 5rem;
    height: 5rem;
  }
  
  .avatar-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  
  .card-title {
    font-size: 1.25rem !important;
  }
}
/* Navbar Background */
.bg-card-bg {
  background: var(--card-bg);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.logo {
  height: 4rem; /* Adjust as needed */
  background-color: #ffcc00; /* Highlight color */
  padding: 10px;
  border-radius: 8px; /* Rounded edges */
  display: block;
  margin: 0 auto; /* Center if needed */
}
/* Navbar Logo */
.text-poker-gold {
  color: var(--poker-gold);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Navbar Links */
.nav-link {
  position: relative;
  transition: all 0.2s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--poker-gold);
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  transform: scaleX(1);
}

.perspective-1000 {
  perspective: 1000px;
}

.font-rye {
  font-family: 'Rye', cursive !important;
}

.font-family-rye {
  font-family: 'Rye', cursive !important;
}

/* Mobile Menu */
.mobile-menu {
  background: var(--card-bg);
  border-top: 1px solid var(--poker-gold-transparent);
}

/* Navbar Button Overrides */
.navbar-button {
  background: transparent !important;
  border: 1px solid var(--poker-gold) !important;
  color: var(--poker-gold) !important;
  transition: all 0.2s ease !important;
}

.navbar-button:hover {
  background: var(--poker-gold) !important;
  color: var(--poker-dark) !important;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Menu Button */
.menu-button {
  color: var(--poker-gold);
  transition: all 0.2s ease;
}

.menu-button:hover {
  color: var(--gold-light);
}

/* Navbar Text */
.text-poker-gold\/60 {
  color: rgba(255, 215, 0, 0.6);
}

.text-poker-gold\/80 {
  color: rgba(255, 215, 0, 0.8);
}

/* Mobile Menu Item Hover */
.hover\:bg-black\/20:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
/* Table Styles */
.table-wrapper {
  overflow-x: auto;
  background: var(--card-bg);
  border-radius: 0.5rem;
  border: 1px solid var(--poker-gold);
}

/* Table Headers */
thead tr {
  background: var(--poker-black-transparent);
}

th {
  color: var(--poker-gold) !important;
  font-family: 'Rye', cursive !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 1rem !important;
}

/* Table Cells */
td {
  color: var(--poker-gold) !important;
  padding: 1rem !important;
  transition: all 0.2s ease;
}

/* Table Rows */
tr {
  border-bottom: 1px solid var(--poker-gold-transparent) !important;
}

tr:hover td {
  background: var(--poker-black-transparent);
}

/* Status Badges */
.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

/* Empty State */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  color: var(--poker-gold-transparent);
}

/* Loading State */
.loading-spinner {
  color: var(--poker-gold);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Icons in Headers */
.header-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  opacity: 0.8;
}

/* Table Hover Effects */
tr:hover .status-badge {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* Utility Classes */
.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}