@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --poker-green: #0a3810;
  --poker-dark: #001502;
  --poker-gold: #ffd700;
  --input-bg: rgba(0, 0, 0, 0.2);
}

body {
  background-image: linear-gradient(45deg, #0a3810 25%, #082b0c 25%, #082b0c 50%, #0a3810 50%, #0a3810 75%, #082b0c 75%, #082b0c 100%);
  background-size: 56.57px 56.57px;
  color: white;
  min-height: 100vh;
}

/* Custom input styling */
input {
  background: var(--input-bg);
  border: 1px solid var(--poker-gold);
  color: white;
  padding: 0.5rem;
  width: 100%;
  border-radius: 4px;
  outline: none;
}

input:focus {
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.3);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}